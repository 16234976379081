<template>
  <div
    class="the-title-section"
    :class="{
      'center-on-mobile': isMobileCentered,
      padded: isPadded,
      'main-title-only': !subTitle,
      'even-bottom': isEvenBottomPadding
    }"
    :style="{ marginTop }"
  >
    <div>
      <h1>{{ title }}</h1>
      <h2 v-if="subTitle">{{ subTitle }}</h2>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      marginTop: '50px'
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    isMobileCentered: {
      type: Boolean,
      default: false
    },
    isPadded: {
      type: Boolean,
      default: false
    },
    isEvenBottomPadding: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$root.$on('header-height-changed', this.updateTopMargin)
  },
  beforeDestroy() {
    this.$root.$off('header-height-changed', this.updateTopMargin)
  },
  methods: {
    updateTopMargin(headerHeight) {
      this.marginTop = headerHeight + 'px'
    }
  }
}
</script>
<style lang="scss" scoped>
.the-title-section {
  background-image: url('../assets/images/bg-horizontal-combined-stripe-title.jpg');
  background-color: $primary-color-bg;
  background-repeat: repeat;
  background-position: top center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin-top: 64px;

  &.even-bottom {
    > div {
      h1 {
        + h2 {
          padding: 0 0 31px;
        }
      }
    }
  }

  &.center-on-mobile {
    > div {
      h1,
      h2 {
        text-align: center;
      }
    }
  }

  &.main-title-only {
    > div {
      h1 {
        padding: 38px 0 43px 0;
        font-size: 1.71429rem;
      }
    }
  }

  &.padded {
    > div {
      h1 {
        padding: 30px 0 34px 0;
      }
    }
  }

  > div {
    min-height: 88px;
    max-width: 960px;
    margin: 0px auto;
    padding: 0 20px;

    h1 {
      font-size: 2rem;
      color: #fff;
      margin: 0;
      line-height: 1.21886em;
      font-size: 1.71429rem;
      padding: 24px 0 8px 0;
      font-weight: 400;
    }

    h2 {
      font-size: 1rem;
      color: #fff;
      font-style: italic;
      text-align: left;
      margin: 0;
      padding: 0 0 24px;
      line-height: 1.21886em;
      font-weight: 300;
    }
  }
}
@media (min-width: $desktop-min-width) {
  .the-title-section {
    margin-top: 50px;

    &.center-on-mobile {
      > div {
        h1,
        h2 {
          text-align: left;
        }
      }
    }

    &.padded {
      > div {
        h1 {
          padding: 38px 24px;
        }
      }

      &.main-title-only {
        > div {
          h1 {
            font-size: 2.2rem;
          }
        }
      }
    }

    &.main-title-only {
      > div {
        h1 {
          font-size: 2.286rem;
          padding: 34px 32px 38px 32px;
        }
      }
    }

    > div {
      min-height: 185px;

      h1 {
        font-size: 2.28571rem;
        padding: 25px 0 5px 0;
      }

      h2 {
        padding: 0;
      }
    }
  }
}

@media (min-width: 960px) {
  .the-title-section {
    &.padded {
      > div {
        h1 {
          padding: 38px 32px;
        }
      }
    }

    > div {
      padding: 0 10px;
    }
  }
}
</style>
