var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "the-title-section",
      class: {
        "center-on-mobile": _vm.isMobileCentered,
        padded: _vm.isPadded,
        "main-title-only": !_vm.subTitle,
        "even-bottom": _vm.isEvenBottomPadding,
      },
      style: { marginTop: _vm.marginTop },
    },
    [
      _c("div", [
        _c("h1", [_vm._v(_vm._s(_vm.title))]),
        _vm.subTitle ? _c("h2", [_vm._v(_vm._s(_vm.subTitle))]) : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }