var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "one-col-box",
      class: {
        wide: _vm.isWide,
        "even-wider": _vm.isEvenWider,
        "start-higher": _vm.startHigher,
        "no-transform": _vm.noTransform,
        "rounded-corners": _vm.roundedCorners,
        "no-padding": _vm.noPadding,
        "no-background": _vm.noBackground,
      },
      style: { marginTop: _vm.marginTop },
    },
    [_c("div", { staticClass: "content" }, [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }